.container
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  overflow: hidden
  position: relative
  
.cameraVideo
  display: none

.mainCanvas
  width: 100% 
  height: 100%
  object-fit: cover

.topPanel
  position: absolute
  top: 0
  left: 0
  right: 0
  pointer-events: none
  height: 5em
  display: flex
  justify-content: center
  align-items: center
  color: white
  font-weight: 700
  flex-direction: column
  div
    text-align: center
    line-height: 1.5em

.uploadBtn
  position: absolute
  bottom: 2em
  left: 50%
  transform: translateX(-50%)
  transition: opacity 0.3s, transform 0.3s
  white-space: nowrap
  
  &.hide
    transform: translateY(3em)  translateX(-50%)
    opacity: 0
    
.patternTitle
  position: absolute
  top: -1.7em
  text-align: center
  left: 0
  right: 0
  color: white
  font-weight: 700
  
.overlay
  position: fixed
  pointer-events: none
  top: 0
  left: 0
  bottom: 20vh
  right: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

  div
    font-size: 1.5em
    font-weight: 700
    color: rgba(255, 255, 255, 0.4)

  img
    width: 30vw
    margin-top: 20px

  &.hide
    display: none

.bottomPanel
  position: absolute
  bottom: 0
  left: 0
  right: 0
  display: flex
  align-items: center
  flex-direction: column

  .pattern
    width: 4em
    height: 4em
    border-radius: 50%
    margin: 0 auto
    transform: scale(0.8)
    border: none
    outline: none
    padding: 0
    box-sizing: border-box
    background: none
    transition: transform 0.2s, padding 0.2s

    >div
      background-size: cover
      border-radius: 50%
      width: 100%
      height: 100%

  :global(.swiper-slide-active) .pattern
    transform: scale(1)
    padding: 4px
    background: linear-gradient(130deg, #F6414B, #6782B4)

.bottomButtons
  display: flex
  align-self: stretch
  background-color: #242424

  button
    flex: 1 1 50px
    border: none
    background: none
    color: white
    font-weight: 500
    border-top: 1px solid #3C3C3C
    height: 3.6em

    &:not(:last-child)
      border-right: 1px solid #3C3C3C

