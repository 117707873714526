.input
  background-color: #DCDFF1
  height: 3.4em
  display: flex
  align-items: center
  border-radius: 2em
  padding-left: 1.4em

  button
    background: none
    border: none
    padding: 0
    padding-left: 0.6em
    padding-right: 1em
    align-self: stretch

    img
      padding-top: 0.2em