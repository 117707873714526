$size: 2.5em
$thumb-size: $size * 0.4
$color: #F6414B
  
.container
  position: absolute
  right: 0
  height: 12em
  margin-top: -8em
  top: 50%
  width: $size
  touch-action: none
  
  .lineWrapper
    height: 100%
    width: 100%

  .line
    width: 0.3em
    height: 100%
    background-color: rgba($color, 0.7)
    border-radius: 0.2em
    margin: 0 auto

  .thumb, .thumbBorder
    position: absolute
    width: $thumb-size
    height: $thumb-size
    transform: translate(-50%, -50%)
    background-color: $color
    border-radius: 1.5em
    border: none
    outline: none
    left: $size * 0.5
    padding: 0
    top: 0
    font-size: 1em
    z-index: 2

  .thumbBorder
    width: $thumb-size*2.5
    height: $thumb-size*2.5
    transform: translate(-50%, -50%) scale(0)
    transition: transform 0.25s
    background-color: rgba($color, 0.3)
    pointer-events: none
    &.active
      transform: translate(-50%, -50%) scale(1)

