.message
  position: fixed
  top: 10%
  left: 50%
  transform: translate(-50%, -50%)
  pointer-events: none
  background-color: white
  padding: 1.2em 2.2em
  border-radius: 1em
  max-width: 90vw
  transition: opacity 0.3s
  width: fit-content
  
  &.hide
    opacity: 0