@import components
  
html, body
  margin: 0
  padding: 0
  font-size: 16px
  font-family: 'Ubuntu', sans-serif

  @media (max-width: 400px)
    font-size: 14px

.btn
  background-color: #F6414B
  color: white
  font-weight: 700
  border: none
  padding: 1.2em 2em
  border-radius: 2em
  font-size: 0.95em
  cursor: pointer

.page
  background-image: url("./background.svg")
  background-size: 300%
  background-position: center
  overflow: hidden
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  display: flex
  flex-direction: column
  text-align: center
  align-items: center
  font-weight: 300
  color: #222222
  padding: 0 1em

  a
    color: #17418F

.page-content
  display: flex
  flex-direction: column
  flex: 1 1 auto
  justify-content: center
  align-items: center