.rotateTool
  position: absolute
  top: 50%
  left: 0.5em
  width: 3em
  height: 3em
  background-color: #F6414B
  border-radius: 50%
  border: none

  &:active
    background-color: #D92E38